import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout, PostCard, Pagination, Container, FeaturedPostCard } from '../components/common';
import { MetaData } from '../components/common/meta';
import saveEmail from '../helpers/saveEmail';
import getFeaturedPost from '../helpers/getFeaturedPost';
import styled from 'styled-components';

/**
* Main index page (home page)
*
* Loads all posts from Ghost and uses pagination to navigate through them.
* The number of posts that should appear per page can be setup
* in /utils/siteConfig.js under `postsPerPage`.
*
*/

const PostFeed = styled.section`
    display: grid;
    justify-content: space-between;
    grid-gap: 6rem;
    grid-template-columns: 1fr 1fr 1fr;
    @media (max-width: 980px) {
        grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 680px) {
        grid-template-columns: 1fr;
    }
`;

const Index = ({ data, location, pageContext }) => {
    const posts = data.allGhostPost.edges;

    //state
    const [email, setEmail] = useState('');
    const [confirmed, setConfirmed] = useState(false);
    const [error, setError] = useState(false);

   

    const featuredPost = getFeaturedPost(posts);
    return (
        <>
            <MetaData location={location} />
            <Layout
                headerBackText='Home'
                headerBackLink='https://getcampfire.com/'
                headerButtonText='Get a demo'
                headerButtonLink='/'
                onFooterEmailChange={value => setEmail(value)}
                footerEmail={email}
                footerError={error}
                footerSubmit={(e) => saveEmail(e, email, setConfirmed, setError)}
                footerEmailConfirmed={confirmed}
            >
                <Container>
                    {
                        featuredPost &&
                        <FeaturedPostCard post={featuredPost} />
                    }
                    <PostFeed>
                        {posts.map(({ node }) => (
                            // The tag below includes the markup for each post - components/common/PostCard.js
                            <PostCard key={node.id} post={node} />
                        ))}
                    </PostFeed>
                    <Pagination pageContext={pageContext} />
                </Container>
            </Layout>
        </>
    )
}

Index.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}
export default Index

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query GhostPostQuery($limit: Int!, $skip: Int!) {
    allGhostPost(
        sort: { order: DESC, fields: [published_at] },
        limit: $limit,
        skip: $skip
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
  }
`
